<template>
    <div style="position: relative; width: 100%; height: 100%" id="page">
        <transition-group name="list-complete" class="list-complete" tag="div">
            <div v-if="showLeft" class="leftShowClass" :key="'left'">
                <div style="width: 100%; height: 100px; display: flex; justify-content: center">
                    <div>
                        <img src="../../public/efLogo.png" route="/main/menu/console" @click="menuPath = []" />
                    </div>
                </div>
                <hr style="margin: 0 37px; background-color: #d1baff; opacity: 0.4; height: 1px; border: none" />
                <el-scrollbar style="width: 100%; height: calc(100% - 101px); max-width: 230px" wrap-class="hiddenX">
                    <el-menu
                        class="asidemenu"
                        @select="selectMenu"
                        :uniqueOpened="true"
                        :router="true"
                        style="text-align: center; max-width: 230px; overflow: hidden"
                    >
                        <el-menu-item index="guide" :route="{ name: 'guide' }">流程引导页</el-menu-item>
                        <el-submenu v-for="(m, idx) in menu" :index="idx.toString()" :key="m.name">
                            <template slot="title" style="text-align: left">
                                <span style="font-size: 16px">{{ m.name }}</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item
                                    v-for="(smu, jdx) in m.list"
                                    :index="jdx.toString()"
                                    :route="routerPath(smu)"
                                    :key="smu.name"
                                >
                                    <span style="color: #999">{{ smu.name }}</span>
                                </el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                    </el-menu>
                </el-scrollbar>
            </div>
            <div class="rightPartClass" style="position: relative" :key="'right'" v-if="true">
                <div class="rightPartTitle">
                    <div style="margin-left: 30px">
                        <el-button @click="switchHideShow" size="mini">显示/隐藏菜单</el-button>
                    </div>
                    <div style="margin-right: 50px">
                        <div class="feedbackwrap" @click="gotoFeedback" style="text-align: center">意见反馈</div>
                    </div>
                    <div style="margin-right: 30px">
                        <div class="downwrap" style="text-align: center">
                            <div slot="title" @mouseover="mouseOver" @mouseleave="mouseLeave">
                                <!--                                <img class="downloadIcon" src="../assets/downApp/icon.png" />-->
                                <div style="display: inline-block; vertical-align: middle">应用下载</div>
                            </div>
                            <div class="downLoadqr" v-if="showDownloadApp">
                                <div style="display: flex; margin-bottom: 30px">
                                    <div style="flex: 1">
                                        <img
                                            class="downLoadqrImg"
                                            :src="require(`../assets/downApp/${downloadAppsrc}.png`)"
                                        />
                                        <div>朗勤心选配送App下载</div>
                                    </div>
                                    <div class="down-line"></div>
                                    <div style="flex: 1">
                                        <img
                                            class="downLoadqrImg"
                                            :src="require(`../assets/downApp/${downloadCheckAppsrc}.png`)"
                                        />
                                        <div>盘点助手App下载</div>
                                    </div>
                                </div>
                                <div>请使用手机浏览器扫码下载安装包</div>
                            </div>
                        </div>

                        <el-menu mode="horizontal">
                            <!--                            <el-menu-item index="6" style="width: 200px !important; position: relative">-->

                            <!--                            </el-menu-item>-->
                            <el-menu-item
                                index="5"
                                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                            >
                                <span slot="title" @click="turnToHelp">帮助中心</span>
                            </el-menu-item>
                            <el-submenu index="2">
                                <template slot="title">{{ $store.state.session.name }}</template>
                                <el-menu-item
                                    index="2-1"
                                    @click="handleInfo"
                                    v-if="hasPrivilege('menu.system.staff.show')"
                                    >基本资料详情
                                </el-menu-item>
                                <el-menu-item index="2-2" @click="handleClickRepass">修改密码</el-menu-item>
                                <el-menu-item index="2-4" @click="changeGroup">切换企业</el-menu-item>
                                <el-menu-item index="2-5" @click="openSystemUpdateUrl">查看系统更新日志</el-menu-item>
                                <el-menu-item index="2-6" @click="changePhoneNumber">更换手机号码</el-menu-item>
                            </el-submenu>
                            <el-menu-item index="4" @click="logout">
                                <i class="el-icon-setting" />
                                <span slot="title">退出登录</span>
                            </el-menu-item>
                        </el-menu>
                    </div>
                </div>
                <el-drawer
                    size="25%"
                    title="帮助中心"
                    :visible.sync="drawerHelp"
                    direction="rtl"
                    :before-close="handleClose"
                >
                    <div class="helpContent">
                        <div class="searchLine">
                            <el-input
                                clearable
                                style="width: 100%"
                                v-model="helpQuestion"
                                placeholder="搜问题"
                                @keyup.enter.native="searchQuestion"
                            >
                                <i
                                    slot="suffix"
                                    style="margin-top: 12px; margin-right: 10px; cursor: pointer"
                                    class="el-icon-search"
                                    @click="searchQuestion"
                                ></i>
                            </el-input>
                        </div>
                        <div class="noviceGuidanceLine contLine">
                            <div class="helpTitle">新手引导</div>
                            <div style="padding-left: 15px">
                                <div class="helpText">
                                    为了更快更好地投入店铺经营，可按照说明文档一次完成商品，员工，会员信息的初始化设置
                                </div>
                                <div class="helpDownload" @click="downLoadZip">
                                    前往下载 <span class="el-icon-download"></span>
                                </div>
                            </div>
                        </div>
                        <div class="commonProblemLine" style="padding-top: 20px">
                            <div class="helpTitle">
                                <span>常见问题</span>
                                <span class="changeMore" @click="changeQuesetion">换一批</span>
                            </div>
                            <div style="padding-left: 15px">
                                <div class="questionItem">
                                    <div
                                        class="qItemLine"
                                        :key="idx"
                                        v-for="(i, idx) in questionList"
                                        @click="turnQuestion(i)"
                                    >
                                        {{ i.urlName }}
                                    </div>
                                </div>
                                <template v-if="isShowMore">
                                    <div
                                        class="learnMore"
                                        :key="index"
                                        v-for="(item, index) in moreInfo"
                                        @click="turnQuestion(item)"
                                    >
                                        {{ item.urlName }} <span class="el-icon-link"></span>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </el-drawer>
                <el-carousel
                    class="carousel"
                    height="30px"
                    direction="vertical"
                    :autoplay="true"
                    :interval="15000"
                    indicator-position="none"
                    v-if="noticeList && noticeList.length > 0"
                >
                    <el-carousel-item v-for="item in noticeList" :key="item.id">
                        <div style="height: 30px; line-height: 35px; margin-left: 10px; font-size: 12px">通知：</div>
                        <!-- eslint-disable vue/no-v-html -->
                        <div class="medium" v-html="item.noticeTitle"></div>
                        <!--eslint-enable-->
                        <el-button
                            type="text"
                            size="small"
                            v-if="item.noticeDetails"
                            style="margin-left: 25px"
                            @click="showDetail(item)"
                            >查询详情</el-button
                        >
                    </el-carousel-item>
                </el-carousel>
                <div
                    class="navTabs"
                    :class="showLeft ? 'centerPart' : 'centerFull'"
                    style="padding-left: 10px; background-color: white"
                >
                    <el-tabs v-model="selectedTabRouteName" @tab-click="clickTabRoute" @tab-remove="removeTabRoute">
                        <el-tab-pane
                            v-for="tabRoute in tabRoutes"
                            :label="tabRoute.label"
                            :name="tabRoute.routeName"
                            :closable="tabRoute.routeName !== 'console'"
                            :key="tabRoute.routeName"
                        />
                    </el-tabs>
                </div>
                <el-scrollbar style="width: 100%; height: calc(100% - 150px)" wrap-class="hiddenX">
                    <div :class="showLeft ? 'centerPart' : 'centerFull'">
                        <section>
                            <transition name="fade-transform" mode="out-in">
                                <keep-alive :include="cachedComponentNamesOfRoute">
                                    <router-view ref="routeView" style="background-color: #f5f7ff" />
                                </keep-alive>
                            </transition>
                        </section>
                    </div>
                </el-scrollbar>
                <div class="smallDeptDiv" v-if="(this.$store.state.overDepts || []).length > 0" v-drag>
                    <DeptRenewalBizDialog
                        ref="deptRenewalBizDialogTwo"
                        @closeDeptRenewalBizDialog="closeMainDeptRenewalBizDialog"
                    />
                </div>
            </div>
        </transition-group>
        <el-dialog title="通知详情" :visible.sync="noticeFlag" width="40%" class="spec-dialog">
            <!-- eslint-disable vue/no-v-html -->
            <div style="font-size: 21px; margin-top: -40px; margin-bottom: -13px" v-html="notice.noticeTitle"></div>
            <!--eslint-enable-->
            <div style="font-size: 13px; padding-bottom: 16px; border-bottom: 1px solid #f0f0f0">
                {{ notice.createTime }}
            </div>
            <div style="overflow: hidden; margin-bottom: 100px" v-dompurify-html="notice.noticeDetails"></div>
        </el-dialog>
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            :before-close="handleClose"
            width="30%"
            top="23vh"
            @close="resetFormData"
            :close-on-press-escape="false"
        >
            <!-- 短信验证 -->
            <div v-show="phoneNumberFlag" class="smsVerification">
                <div class="diaLogBox">
                    <el-form ref="smsVerification" :model="smsVerificationFormData" :rules="smsVerificationRules">
                        <el-form-item label="请输入原手机号码：" prop="mobile" style="margin-bottom: 20px">
                            <el-input
                                v-model="smsVerificationFormData.mobile"
                                placeholder="请输入原手机号码"
                                style="width: 55%"
                            />
                        </el-form-item>
                        <el-form-item label="请输入验证码：" prop="verifyCode">
                            <el-input
                                placeholder="请输入验证码"
                                v-model="smsVerificationFormData.verifyCode"
                                class="input-with-select"
                                style="margin-left: 28px; width: 55%"
                            >
                                <el-button v-show="show" @click="getVerificationCode" slot="append">验证码</el-button>
                                <el-button v-show="!show" slot="append">{{ count }}秒</el-button>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <p class="dialog-footer-p">
                        <span @click="switchOtherBind(true)">使用账号密码换绑</span>
                    </p>
                    <div class="buttonBox">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submitBtn('smsVerification')">下一步</el-button>
                    </div>
                </span>
            </div>
            <!-- 账号密码验证 -->
            <div v-show="accountPasswordFlag" class="smsVerification">
                <div class="diaLogBox">
                    <el-form ref="userNameLogin" :model="accountPasswordFormData" :rules="accountPasswordRules">
                        <el-form-item label="请输入您的账号：" prop="username" style="margin-bottom: 20px">
                            <el-input
                                v-model="accountPasswordFormData.username"
                                placeholder="请输入您的账号"
                                style="width: 55%"
                            />
                        </el-form-item>
                        <el-form-item label="请输入密码：" prop="password">
                            <el-input
                                placeholder="请输入密码"
                                v-model="accountPasswordFormData.password"
                                class="input-with-select"
                                style="margin-left: 28px; width: 55%"
                            />
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <p class="dialog-footer-p">
                        <span @click="switchOtherBind(false)">使用原手机换绑</span>
                    </p>
                    <div class="buttonBox">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submitBtn('userNameLogin')">下一步</el-button>
                    </div>
                </span>
            </div>
            <!-- 变更手机号 -->
            <div v-show="changeMobileFalag" class="smsVerification">
                <p v-show="changeMobileFalag" class="tipsText">如您手机号无法使用，请联系客服更换</p>
                <div class="diaLogBox">
                    <el-form ref="changeMobile" :model="changeMobileFormData" :rules="changeMobileRules">
                        <el-form-item label="请输入新的手机号：" prop="mobile" style="margin-bottom: 20px">
                            <el-input
                                v-model="changeMobileFormData.mobile"
                                placeholder="请输入新的手机号"
                                style="width: 55%"
                            />
                        </el-form-item>

                        <el-form-item label="请输入验证码：" prop="verifyCode" style="margin-bottom: 20px">
                            <el-input
                                placeholder="请输入验证码"
                                v-model="changeMobileFormData.verifyCode"
                                class="input-with-select"
                                style="margin-left: 28px; width: 55%"
                            >
                                <el-button v-show="show" @click="getNewMobileVerificationCode" slot="append"
                                    >验证码</el-button
                                >
                                <el-button v-show="!show" slot="append">{{ count }}秒</el-button>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <div class="buttonBox">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submitBtn('changeMobile')">提交</el-button>
                    </div>
                </span>
            </div>
        </el-dialog>
        <DeptRenewalBizDialog ref="deptRenewalBizDialog" @closeDeptRenewalBizDialog="closeMainDeptRenewalBizDialog" />
    </div>
</template>

<script>
import UrlUtils from '../js/UrlUtils';
import Utils from '../js/Util';
import Util from 'js/Util';
import DeptRenewalBizDialog from 'views/menu/system/component/DeptRenewalBizDialog';

export default {
    name: 'EfMain',
    components: { DeptRenewalBizDialog },
    data() {
        return {
            groupName: '',
            menu: [],
            menuPath: [],
            timer: null,
            timer2: null,
            //当前打开的tab标签route的name
            selectedTabRouteName: null,
            showLeft: true,
            form: {
                id: 1,
            },
            url: {
                page: '/system/backConfig/helpCenter',
                notice: '/system/notice/noticeInfoList',
            },
            helpName: '',
            helpUrl: '',
            helpValid: '',
            smsVerificationFormData: {
                mobile: '',
                verifyCode: '',
            },
            accountPasswordFormData: {
                username: '',
                password: '',
            },
            changeMobileVerifyFormData: {
                // 新手机号获取验证码参数
                mobile: null,
                token: null,
            },
            changeMobileFormData: {
                // 变更新手机号参数
                mobile: '',
                token: null,
                verifyCode: '',
            },
            smsVerificationRules: {
                mobile: [{ required: true, message: '请输入原手机号', trigger: 'blur' }],
                verifyCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
            },
            accountPasswordRules: {
                username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
            },
            changeMobileRules: {
                mobile: [{ required: true, message: '请输入新的手机号', trigger: 'blur' }],
                verifyCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
            },
            dialogVisible: false,
            phoneNumberFlag: true,
            accountPasswordFlag: false,
            changeMobileFalag: false,
            countDownNum: null,
            show: true,
            countDownTimer: null,
            count: 60,
            sixCheckReg: /\d{6}/,
            // downloadAppsrc: 'receivingTest', //测试环境
            // downloadAppsrc: 'receivingDisplay', //演示环境
            downloadAppsrc: 'receiving', //正式环境
            // downloadCheckAppsrc: 'checkTest', //测试环境
            // downloadCheckAppsrc: 'checkDisplay', //演示环境
            // downloadCheckAppsrc: 'checkDev', //开发环境
            downloadCheckAppsrc: 'check', //正式环境
            showDownloadApp: false,
            noticeList: [],
            noticeFlag: false,
            notice: {},
            checkButton: false,
            drawerHelp: false, //帮助中心弹窗
            helpQuestion: '',
            questionList: [],
            questionPage: 1,
            maxTotal: 1,
            isShowMore: false,
            moreInfo: null,
        };
    },
    // beforeCreate() {
    //     this.checkButton = JSON.parse(localStorage.getItem('checkButton'));
    // },
    async mounted() {
        // if (location.pathname === `${process.env.BASE_URL}main`) {
        UrlUtils.QueryRemote(this, '/authedMenus', (res) => {
            this.menu = res;
        });
        UrlUtils.QueryRemote(this, '/allPrivileges', (res) => {
            this.$store.commit('allPrivileges', res);
        });
        /* 定时器，间隔1分钟检测是否长时间未操作页面  */
        this.timer = window.setInterval(() => {
            //如果前端没超时，则判断后台session是否超时
            UrlUtils.QueryRemote(this, '/checkSession', (rst) => {
                if (Utils.isEmpty(rst['code'])) {
                    //无法检测到session数据就强制退出
                    this.logout();
                }
            });
        }, 1000 * 10 * 60);
        //导航到console页面和引导页
        await this.toggleTabRouteTo('console');
        // this.handleQuery();
        this.getNotice();
        this.timer2 = window.setInterval(() => {
            //獲取系統通知
            this.getNotice();
        }, 1000 * 60);
        // } else {
        UrlUtils.QueryRemote(
            this,
            '/checkSession',
            (rst) => {
                if (rst['code']) {
                    this.$store.commit('setSession', {
                        code: rst['code'],
                        realName: rst['name'],
                        staffType: rst['staffType'],
                        deptType: rst['deptType'],
                        superStaffFlag: rst['superStaffFlag'],
                    });
                    // this.$router.push({
                    //     path: '/chooseGroup',
                    // });
                } else {
                    //未登录
                    this.$router.push({ name: 'login' });
                }
            },
            (errorMsg) => {
                this.$router.push({ name: 'login' });
            }
        );
        this.getInformation();
        // }
    },
    watch: {
        //监控所有路由
        $route(toRoute, fromRoute) {
            this.selectedTabRouteName = toRoute.name;
            this.$store.commit('addTabRoute', { toRoute, fromRoute });
            this.showOverDept(toRoute);
        },
    },
    computed: {
        //已缓存的routeView中组件的name列表
        cachedComponentNamesOfRoute() {
            return this.tabRoutes.filter((e) => e.keepAliveFlag).map((e) => e.componentNameOfRoute);
        },
        tabRoutes() {
            return this.$store.state.tabRoutes;
        },
        dialogTitle() {
            let title;
            if (this.phoneNumberFlag) {
                title = '旧手机号验证';
            } else if (this.accountPasswordFlag) {
                title = '账号密码验证';
            } else if (this.changeMobileFalag) {
                title = '新手机号验证';
            }
            return title;
        },
    },
    beforeDestroy() {
        if (this.timer) {
            window.clearInterval(this.timer);
            this.timer = null;
        }
        if (this.timer2) {
            window.clearInterval(this.timer2);
            this.timer2 = null;
        }
        //移除所有tab标签
        this.$store.commit('delAllTabRoutes');
        this.$store.commit('addOverDepts', []);
    },
    methods: {
        showOverDept(toRoute, overDepts = []) {
            toRoute = toRoute || { meta: {} };
            const _overDepts = (overDepts || []).length > 0 ? overDepts : this.$store.state.overDepts || [];
            if (_overDepts.length > 0) {
                this.$nextTick(() => {
                    this.$refs.deptRenewalBizDialogTwo.show(_overDepts, false, true);
                    if (toRoute.meta.showDeptEffectiveDialog) {
                        this.$refs.deptRenewalBizDialog.show(_overDepts, true);
                    }
                });
            }
        },
        getInformation() {
            this.$http.get('/system/notice/checkBounced?overDeptsFlag=true', {}).then((res) => {
                const _depts = [];
                (res.data.data.overDepts || []).forEach((row) =>
                    _depts.push({ renewalShop: row.name, deptCode: row.code, renewalCode: null })
                );
                this.$store.commit('addOverDepts', _depts);
                this.showOverDept(null, _depts);
            });
        },
        closeMainDeptRenewalBizDialog() {
            if (this.$route.meta.showDeptEffectiveDialog) {
                this.removeTabRoute(this.$route.name);
            }
        },
        iconMouse() {
            setTimeout(() => {
                this.showDownloadApp = !this.showDownloadApp;
            }, 350);
        },
        //新增意见反馈单
        gotoFeedback() {
            // this.$router.push({ name: 'menu.user.feedBack.addFeedback' });
            Util.nameJump(this, 'menu.user.feedBack.addFeedback');
        },
        mouseOver() {
            this.showDownloadApp = true;
        },
        mouseLeave() {
            this.showDownloadApp = false;
        },
        //获取系统通知
        getNotice() {
            this.$http.get(this.url.notice).then((rst) => {
                this.noticeList = rst.data.data || [];
                this.noticeList.forEach((item) => {
                    if (item.noticeTitle && item.noticeEvent === 1) {
                        const a = JSON.parse(item.noticeTitle).content;
                        const b = JSON.parse(item.noticeDetails).content;
                        item.noticeTitle = a;
                        item.noticeDetails = b;
                    }
                });
            });
        },
        showDetail(row) {
            this.noticeFlag = true;
            this.notice = row;
        },
        //获取帮助中心字段
        handleQuery() {
            this.$http.get(this.url.page, { params: this.form }).then((rst) => {
                if (rst.data.data) {
                    this.helpName = rst.data.data[0].urlName;
                    this.helpUrl = rst.data.data[0].url;
                    this.helpValid = rst.data.data[0].valid;
                }
            });
        },
        turnQuestion(item) {
            window.open(item.url);
        },
        //下载
        downLoadZip() {
            this.$http({
                url: '/system/backConfig/helpCenter/descDocInfo',
                method: 'get',
            }).then((data) => {
                window.location.href = data.data.data.url;
            });
        },
        //回车搜索问题
        searchQuestion() {
            var _params = {
                type: 1,
                search: this.helpQuestion,
                flag: false,
                page: this.questionPage,
                limit: 10,
            };
            this.$http.get('/system/backConfig/helpCenter/pageExtend', { params: _params }).then((res) => {
                var _res = res.data;
                this.maxTotal = (_res.count + _params.limit - 1) / _params.limit;
                this.questionList = _res.data;
            });
        },
        /*
         * 换一批
         * */
        changeQuesetion() {
            var _params = {
                type: 1,
                search: this.helpQuestion,
                flag: false,
                page: this.questionPage <= this.maxTotal - 1 ? ++this.questionPage : (this.questionPage = 1),
                limit: 10,
            };
            this.$http.get('/system/backConfig/helpCenter/pageExtend', { params: _params }).then((res) => {
                var _res = res.data;
                this.maxTotal = (_res.count + _params.limit - 1) / _params.limit;
                this.questionList = _res.data;
            });
        },
        //帮助中心跳转
        turnToHelp() {
            this.questionPage = 1;
            // window.open(this.helpUrl); 原帮助中心跳转链接
            this.drawerHelp = true;
            var _params = {
                type: 1,
                search: this.helpQuestion,
                flag: false,
                page: this.questionPage,
                limit: 10,
            };
            this.$http.get('/system/backConfig/helpCenter/pageExtend', { params: _params }).then((res) => {
                var _res = res.data;
                this.maxTotal = (_res.count + _params.limit - 1) / _params.limit;
                this.questionList = _res.data;
            });
            var _Moreparams = {
                type: 2,
                flag: false,
            };
            this.$http.get('/system/backConfig/helpCenter/pageExtend', { params: _Moreparams }).then((res) => {
                var _resMore = res.data;
                if (_resMore) {
                    this.isShowMore = true;
                    this.moreInfo = _resMore.data;
                }
            });
        },
        // 去续费
        renew() {
            this.$http.get('/system/notice/checkBounced').then((res) => {
                this.checkButton = res.data.data.checkButton;
            });
        },
        toRenew() {
            this.$router.push({ name: 'menu.system.dept' });
        },
        //打开系统更新日志页面
        openSystemUpdateUrl() {
            window.open(
                'https://thoughts.aliyun.com/sharespace/606fc3136e19d5001ad5fd9a/docs/60741979e9efc6001a163c3a'
            );
        },
        switchHideShow() {
            this.showLeft = !this.showLeft;
        },
        showGuidePage() {
            Util.nameJump(this, 'guide');
        },
        selectMenu(index, indexPath) {
            if (!indexPath[0] || !this.menu[indexPath[0]]) {
                return;
            }
            const mu = this.menu[indexPath[0]].list[indexPath[1]];

            const path = [this.menu[indexPath[0]].name, mu.name];
            const p = {
                name: mu['privilegeFlag'],
                path: path,
            };
            this.$store.commit('regRouteMap', p);
        },
        routerPath(menu) {
            return { name: menu.privilegeFlag };
        },
        clickTabRoute(tab) {
            this.toggleTabRouteTo(tab.name);
        },
        removeTabRoute(tabRouteName) {
            //移除tab
            let selectedTabRouteName = this.selectedTabRouteName;
            if (selectedTabRouteName === tabRouteName) {
                //如果关闭的标签页是当前标签页，则将当前标签页切换成后一个或前一个tab
                this.tabRoutes.forEach((tabRoute, index) => {
                    if (tabRoute.routeName === tabRouteName) {
                        const nextTab = this.tabRoutes[index + 1] || this.tabRoutes[index - 1];
                        if (nextTab) {
                            selectedTabRouteName = nextTab.routeName;
                        }
                    }
                });
            }
            //根据route的name路由页面
            this.toggleTabRouteTo(selectedTabRouteName);
            this.selectedTabRouteName = selectedTabRouteName;
            //删除标签页
            this.$store.commit('delTabRoute', tabRouteName);
        },
        //切换tab
        async toggleTabRouteTo(tabRouteName) {
            const tabRoute = this.tabRoutes.find((e) => e.routeName === tabRouteName);
            //切换到tab时，使用缓存
            if (tabRoute) {
                await this.$router.push({
                    name: tabRoute.routeName,
                    query: tabRoute.query,
                    params: { ...tabRoute.params },
                });
            } else {
                //不存在，则只有使用name路由，无法使用没有保存的query和params
                await this.$router.push({ name: tabRouteName });
            }
        },
        logout() {
            this.$http
                .get('/apiLogout')
                .then((response) => {
                    if (response.data.status === 200) {
                        console.warn('退出成功');
                    }
                })
                .finally(() => {
                    window.clearInterval(this.timer);
                    window.clearInterval(this.timer2);
                    this.timer = null;
                    this.timer2 = null;

                    //解绑事件
                    document.onclick = null;
                    //清除session数据
                    this.$store.commit('setSession', {});
                    this.$store.commit('allPrivileges', []);
                    this.$router.replace({
                        path: '/',
                    });
                });
        },
        changeGroup() {
            this.$router.push('/chooseGroup');
        },
        handleClickRepass() {
            this.$router.push('/repass');
        },
        handleInfo() {
            const _this = this;
            this.$router.push({ name: 'menu.system.staff.info', params: { code: _this.$store.state.session.code } });
        },
        switchOtherBind(type) {
            if (type) {
                // 切换到账号密码
                this.phoneNumberFlag = false;
                this.accountPasswordFlag = true;
            } else {
                // 切换到原手机
                this.phoneNumberFlag = true;
                this.accountPasswordFlag = false;
            }
            this.show = true;
            clearTimeout(this.countDownTimer);
            this.countDownTimer = null;
            this.count = 0;
            const formName = type ? 'userNameLogin' : 'smsVerification';
            this.$refs[formName].resetFields();
        },
        toNewChangePhoneNumber(data) {
            this.changeMobileVerifyFormData.token = data;
            this.phoneNumberFlag = false;
            this.accountPasswordFlag = false;
            this.changeMobileFalag = true;
            this.count = 0;
            clearTimeout(this.countDownTimer);
            this.countDownTimer = null;
        },
        handleClose(done) {
            done();
        },
        changePhoneNumber() {
            this.dialogVisible = true;
        },
        checkPhoneAndVerifyCode(mobile, verifyCode) {
            let flag = true;
            if (!window.ef.someRegs.mobile.test(mobile)) {
                this.$message.error('手机号格式不正确');
                flag = false;
            } else if (!this.sixCheckReg.test(verifyCode)) {
                this.$message.error('验证码格式不正确');
                flag = false;
            }
            return flag;
        },
        submitBtn: Util.debounce(function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (formName == 'smsVerification') {
                        const { mobile, verifyCode } = this.smsVerificationFormData;
                        if (this.checkPhoneAndVerifyCode(mobile, verifyCode)) {
                            this.$efApi.staffApi.checkOldMobileValidCode(mobile, verifyCode).then((res) => {
                                if (res.status == 200) {
                                    this.show = true;
                                    // 旧手机号短信验证成功 切换到变更新手机号页面
                                    this.$message.success('验证成功');
                                    this.toNewChangePhoneNumber(res.data);
                                } else {
                                    this.$message.error(res.message);
                                }
                            });
                        }
                    } else if (formName == 'userNameLogin') {
                        this.$efApi.staffApi.checkOldAccount(this.accountPasswordFormData).then((res) => {
                            if (res.status == 200) {
                                this.show = true;
                                this.$message.success('验证成功');
                                // 旧账号密码验证成功  切换到变更新手机号页面
                                this.toNewChangePhoneNumber(res.data);
                            }
                        });
                    } else if (formName == 'changeMobile') {
                        const { mobile, token, verifyCode } = this.changeMobileFormData;
                        if (this.checkPhoneAndVerifyCode(mobile, verifyCode)) {
                            // 变更手机号
                            this.$efApi.staffApi.mobileUpdate(mobile, { token, verifyCode }).then((res) => {
                                if (res.status == 200) {
                                    this.show = true;
                                    this.$message.success('手机号修改成功');
                                    this.resetFormData();
                                    this.dialogVisible = false;
                                } else {
                                    this.$message.error(res.message);
                                }
                            });
                        }
                    }
                }
            });
        }),
        resetFormData() {
            this.$refs['smsVerification'].resetFields();
            this.$refs['userNameLogin'].resetFields();
            this.$refs['changeMobile'].resetFields();
            setTimeout(() => {
                clearTimeout(this.countDownTimer);
                this.countDownTimer = null;
                this.count = 0;
                this.show = true;
                this.phoneNumberFlag = true;
                this.accountPasswordFlag = false;
                this.changeMobileFalag = false;
            }, 100);
        },
        getVerificationCode: Util.debounce(function () {
            const { mobile } = this.smsVerificationFormData;
            // // 旧手机号验证获取验证码
            if (mobile) {
                if (window.ef.someRegs.mobile.test(mobile)) {
                    this.$efApi.staffApi.sendOldMobileValidCode(mobile).then((res) => {
                        if (res.status == 200) {
                            this.countDown();
                            this.$message.success('短信已发送，请注意查收');
                        }
                    });
                } else {
                    this.$message.error('请输入正确的手机号');
                }
            } else {
                this.$message.error('请先输入手机号');
            }
        }),
        getNewMobileVerificationCode: Util.debounce(function () {
            const { mobile } = this.changeMobileFormData;
            if (mobile) {
                if (window.ef.someRegs.mobile.test(mobile)) {
                    this.changeMobileVerifyFormData.mobile = this.changeMobileFormData.mobile;
                    const { mobile, token } = this.changeMobileVerifyFormData;
                    // 新手机号获取验证码
                    this.$efApi.staffApi.sendOldMobileVerifyCode(mobile, { token }).then((res) => {
                        if (res.status == 200) {
                            this.countDown();
                            this.$message.success('短信已发送，请注意查收');
                        }
                    });
                } else {
                    this.$message.error('请输入正确的手机号');
                }
            } else {
                this.$message.error('请先输入手机号');
            }
        }),
        countDown() {
            const TIME_COUNT = 60;
            if (!this.countDownTimer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.countDownTimer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.countDownTimer);
                        this.countDownTimer = null;
                    }
                }, 1000);
            }
        },
    },
    directives: {
        drag: {
            // 指令的定义
            bind: function (el) {
                const odiv = el; //获取当前元素
                el.onmousedown = (e) => {
                    //算出鼠标相对元素的位置
                    const disX = e.clientX - odiv.offsetLeft;
                    const disY = e.clientY - odiv.offsetTop;
                    let left = '';
                    let top = '';
                    document.onmousemove = (e) => {
                        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                        left = e.clientX - disX;
                        top = e.clientY - disY;
                        var docWidth = document.body.clientWidth; //网页可见宽
                        var docHeight = document.body.clientHeight; //网页可见高
                        if (top > docHeight - 210) {
                            //超下边界(键盘DIV长宽分别为 295px、210px)
                            top = docHeight - 210;
                        }
                        if (top < 0) {
                            //超上边界
                            top = 0;
                        }
                        if (left > docWidth - 295) {
                            //超右边界
                            left = docWidth - 295;
                        }
                        if (left < 0) {
                            //超左边界
                            left = 0;
                        }
                        //绑定元素位置到positionX和positionY上面
                        //移动当前元素
                        odiv.style.left = left + 'px';
                        odiv.style.top = top + 'px';
                    };
                    document.onmouseup = (e) => {
                        document.onmousemove = null;
                        document.onmouseup = null;
                    };
                };
            },
        },
    },
};
</script>

<style>
#page {
    background-color: #f5f7ff;
}

#page .hiddenX {
    overflow-x: hidden;
}

#page .list-complete {
    width: 100%;
    height: 100%;
    display: flex;
}

#page .leftShowClass {
    width: 230px;
    height: 100%;
    max-width: 204px;
    margin-right: 10px;
    transition: all 1s;
    background-color: white;
    box-shadow: 10px 0 10px 0 rgba(245, 247, 255, 0.17);
}

#page .leftHideClass {
    position: absolute;
    width: 0;
    height: 100%;
}

#page .rightPartClass {
    height: 100%;
    width: 100%;
    transition: all 1s;
    background-color: #f5f7ff;
}

#page .rightFullClass {
    position: absolute;
    width: 100%;
    height: 100%;
}

#page .rightPartTitle {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}

#page .rightPartTitle .el-menu,
.el-submenu__title {
    border-bottom: none;
}

#page .rightPartClass .navTabs .el-tabs__header {
    margin-top: 10px;
    background-color: white;
    margin-bottom: 0;
}

#page .rightPartClass .el-tabs__content #page .list-complete-enter,
#page .list-complete-leave-to {
    opacity: 0;
    transform: translateX(-100px);
}

#page .list-complete-leave-active {
    position: absolute;
}

#page .left .el-menu-item {
    border-top: 1px solid #f2f2f2;
    max-width: 230px;
}

#page .rightPartClass .centerPart {
    max-width: 1680px;
}

#page .rightPartClass .centerFull {
    max-width: 1920px;
}

#page .el-scrollbar__wrap {
    overflow-x: hidden;
}

.main .left .el-submenu {
    border-top: 1px solid #f2f2f2;
}

.main .left .el-submenu__title {
    border-top: 1px solid #f2f2f2;
}

.main .footer {
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main .center {
    position: absolute;
    top: 0;
    bottom: 8px;
    left: 8px;
    right: 8px;
    overflow: auto;
    background-color: #f2f2f2;
}

.main .center .path {
    padding: 8px 16px;
    margin-top: 8px;
    background-color: white;
    line-height: 32px;
    text-align: left;
    color: #19c989;
}

.main .el-tabs__content {
    background-color: #f2f2f2;
}

.content {
    height: 900px;
}

.el-input {
    width: auto;
}

.main {
    color: #606266;
}
.buttonBox {
    display: flex;
    justify-content: right;
    padding-right: 100px;
}
.dialog-footer-p {
    margin: 24px 0;
    padding-right: 100px;
    text-align: right;
}
.dialog-footer-p span {
    cursor: pointer;
}
.tipsText {
    font-size: 12px;
    color: #909399;
    text-align: right;
    position: absolute;
    top: 12px;
    right: 70px;
}
.el-form-item__error {
    top: 100%;
    left: 0;
}
.downloadIcon {
    width: 30px;
    height: 30px;
    position: absolute;
    left: -30px;
    top: -5px;
}
.downLoadqr {
    box-sizing: border-box;
    padding-top: 30px;
    width: 360px;
    height: 240px;
    background: #fff;
    border-radius: 10px;
    position: absolute;
    z-index: 99;
    top: 50px;
    left: -150px;
    text-align: center;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.downLoadqrImg {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
}
.down-line {
    width: 1px;
    height: 90px;
    background: #ccc;
    margin-top: 10px;
}
.feedbackwrap {
    position: relative;
    z-index: 999;
    top: 8px;
    left: 350%;
    width: 155px;
    vertical-align: bottom;
    color: #909399;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
}
.downwrap {
    position: relative;
    top: 39px;
    z-index: 999;
    right: 100%;
    width: 155px;
    vertical-align: bottom;
    color: #909399;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
}
.carousel {
    height: 30px;
    margin-top: 10px;
}
.carousel .el-carousel__item {
    display: flex;
    background: #e8faf3;
}
.medium {
    margin: 8px 0;
    font-size: 12px;
}
.medium p {
    margin: 0;
    font-size: 12px;
}
.spec-dialog .el-dialog__body {
    overflow-y: auto;
    height: calc(100vh - 400px);
}
.spec-dialog .el-dialog__header {
    border-bottom: 1px solid #f0f0f0;
}
.helpContent {
    padding: 0 20px;
}
.searchLine {
    margin-bottom: 20px;
}
.helpTitle {
    font-size: 20px;
    margin-bottom: 20px;
}
.helpText {
    font-size: 16px;
    margin-bottom: 20px;
    overflow: hidden;
}
.helpDownload,
.learnMore {
    color: #4991f5;
    cursor: pointer;
    user-select: none;
}
.helpDownload span,
.learnMore span {
    margin-left: 5px;
    font-weight: bold;
}
.contLine {
    padding-bottom: 20px;
    border-bottom: 1px solid #e4ecf9;
}
.changeMore {
    font-size: 14px;
    float: right;
    color: #4991f5;
    margin-top: 10px;
    cursor: pointer;
}
.questionItem {
    margin-bottom: 20px;
}
.qItemLine {
    line-height: 36px;
    cursor: pointer;
}
</style>
<style scoped>
.smallDeptDiv {
    height: 300px;
    width: 600px;
    position: absolute;
    right: 0;
    bottom: 0;
    border: 1px solid #ccc;
    z-index: 999;
    box-shadow: 0 13px 25px 0 rgb(34 2 2 / 10%);
}
.smallDeptDiv >>> .el-dialog__wrapper {
    position: absolute !important;
}
.smallDeptDiv >>> .el-dialog__body {
    padding: 30px 0 0 0;
}
.smallDeptDiv >>> .el-dialog__header {
    padding-bottom: 0;
}
</style>
